require('../scss/app.scss');
require('../fonts/clearsans/clearsans.css');

window.noZensmooth = true;

require('./common/goal.js');
require('./common/js_validation.js');
require('./common/links.js');
require('./common/main.js');
require('./common/pagination.js');
require('./common/respond.js');
import 'slick-carousel';
import '@fancyapps/fancybox';
import '@fancyapps/fancybox/dist/jquery.fancybox.css';
