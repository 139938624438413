import LiveEvent from "../../components/live/live";
import Modal from "../../components/modal/modal";

new LiveEvent('click', '[data-modal]', function openModal(e) {
    e.preventDefault();
    let link = this;
    let modal = new Modal(this, {
        closerText: 'x',
        onFormSuccess: function () {
            if (link.dataset.goal) {
                window.goal(link.dataset.goal);
            }
        }
    });
    return false;
});


$(function () {
    $('[data-slick-block]').slick({
        dots: true,
        arrows: false,
        fade: true,
        autoplay: true,
        autoplaySpeed: 4000,
    });

    if (detectmob()) {
        $('[data-dropper]').on('click', function (e) {
            e.preventDefault();
            var $this = $(this);
            $this.closest('li').toggleClass('mobile-active');
            return false;
        });
        $('[data-mobile-menu-open]').on('click', function () {
            $('.main-menu').toggleClass('open');
        });
    }


    $('[data-slick-banners]').slick({
        dots: false,
        arrows: true,
        slidesToShow: 6,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 1500,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1
                }
            }
        ]
    });

    $('[data-fancybox]').fancybox({
        padding: 0,
        helpers: {
            overlay: {
                locked: false
            }
        }
    });
});


function detectmob() {
    if (navigator.userAgent.match(/Android/i)
        || navigator.userAgent.match(/webOS/i)
        || navigator.userAgent.match(/iPhone/i)
        || navigator.userAgent.match(/iPad/i)
        || navigator.userAgent.match(/iPod/i)
        || navigator.userAgent.match(/BlackBerry/i)
        || navigator.userAgent.match(/Windows Phone/i)
    ) {
        return true;
    } else {
        return false;
    }
}
